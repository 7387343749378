import { FC, ChangeEvent, useState } from 'react';
import { format } from 'date-fns';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  Button
} from '@mui/material';

import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import Payment from '@mui/icons-material/Payment';
import { updateDialogOpen, updateVal, updateDialogOpenCourier, postCustomerNotification, updateDialogOpenLabelNama } from 'src/redux/OrderPostRedux';
import { updateDialogOpen as updateDialogOpenPayment, updateVal as updateValPayment } from 'src/redux/OrderPaymentPostRedux';
import { useAppDispatch } from 'src/reduxhooks';
import OrderInvoice from './OrderInvoice';
import { fetchOrdersList, fetchUserCourierLabelPdf, fetchUserPersonalizedLabelsPdf, fetchUserShipmentLabelPdf, fetchUserShipmentLabelPdfLong, fetchWorkInputFormPdf } from 'src/redux/OrderListRedux';
import { pdf, PDFViewer } from '@react-pdf/renderer';

interface RecentOrdersTableProps {
  className?: string;
  ordersList: any[];
}

interface Filters {
  status?: "settlement" | "pending" | "expire"; 
}

function compare( a: any, b: any ) {
  if ( a.orderId > b.orderId ){
    return -1;
  }
  if ( a.orderId < b.orderId ){
    return 1;
  }
  return 0;
}
const applyFilters = (
  cryptoOrders: any[],
  filters: Filters
): any[] => {
  return cryptoOrders.filter((cryptoOrder) => {
    let matches = true;

    if (filters.status) {
      if (cryptoOrder.paymentStatus !== filters.status) {
        matches = false;
      }
      if (cryptoOrder.paymentStatus === "capture" && filters.status === "settlement") {
        matches = true;
      }
      if (cryptoOrder.paymentStatus === "capture_stockonly" && filters.status === "settlement") {
        matches = true;
      }
    }

    if (filters.status === "expire" && cryptoOrder.paymentStatus === "abandoned") {
      matches = true;
    }

    return matches;
  });
};


const applyPagination = (
  ordersList: any[],
  page: number,
  limit: number
): any[] => {
  if (page === -1) {
    return [];
  }
  return ordersList.slice(page * limit, page * limit + limit);
};


function formatDate(difference) {
  // Arrange the difference of date in days, hours, minutes, and seconds format
  let days = Math.floor(difference / (1000 * 60 * 60 * 24));
  let hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  return " (" + days + " days, " + hours + " hours, " + minutes + " minutes)";
}

const RecentOrdersTable: FC<RecentOrdersTableProps> = ({ ordersList }) => {
  const dispatch = useAppDispatch();

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [filters, setFilters] = useState<Filters>({
    status: "settlement"
  });

  // Invoice PDF
  function generateOrderShippingDetail() {
    const blob = pdf(<OrderInvoice/>).toBlob();
    
    blob.then(function (blob) {
      const fileURL = URL.createObjectURL(blob);
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    });
  }

  const statusOptions = [
    {
      id: 'settlement',
      name: 'Paid'
    },
    {
      id: 'pending',
      name: 'Waiting For Payment'
    },
    {
      id: 'expire',
      name: 'Not Paid'
    },
    {
      id: '',
      name: 'All'
    },
  ];

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));

    setPage(0);
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  var toBeSortedOrdersList = [...ordersList];

  const sortedCryptoOrders = toBeSortedOrdersList.sort(compare);
  const filteredCryptoOrders = applyFilters(sortedCryptoOrders, filters);

  // if (page === -1) {
  //   setPage(Math.floor(filteredCryptoOrders.length / limit));
  // }

  const paginatedCryptoOrders = applyPagination(
    filteredCryptoOrders,
    page,
    limit
  );
  const theme = useTheme();

  const refreshOrderFunction = (arg: string) => {
    setIsRefreshing(true);
    dispatch(fetchOrdersList(""));
    setTimeout(() => {
      setIsRefreshing(false);
    }, 2500);
  }

  return (
    <Card>
      <CardHeader
        action={
          <Box width={240}>
            <Box style={{
              display: "flex",
              flexDirection: "row",
            }}>
              <Button variant="outlined" onClick={() => refreshOrderFunction("")} 
              style={{
                width: "130px",
                marginRight: "8px",
              }}
              disabled={isRefreshing}>
                {isRefreshing ? "Mengupdate..." : "Cek Data Terbaru"}
              </Button>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Payment Status</InputLabel>
                <Select
                  value={filters.status}
                  onChange={handleStatusChange}
                  label="Payment Status"
                  autoWidth
                >
                  {statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        }
        title="Recent Orders"
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={{}}>
              <Typography style={{margin: '6px 0px 6px 13px', color: '#000000', fontSize: '18px'}}>
                <b>JUMLAH LEWAT KIRIM: </b>{ordersList.filter((order) => {
                  if (order.paymentStatus !== "settlement" && order.paymentStatus !== "capture" && order.paymentStatus !== "capture_stockonly") {
                    return false;
                  }

                  const currentDateString = new Date().toLocaleDateString('en-US', {timeZone: 'Asia/Jakarta', day: "2-digit", month: "2-digit"});

                  const orderCreationTime = new Date(order.creationDateTime); // Ingest ISO time

                  var shipTime = new Date();
                  shipTime.setTime(orderCreationTime.getTime() + (1 * 24 * 3600 * 1000));
                  var finalShipTime = new Date();
                  finalShipTime.setTime(orderCreationTime.getTime() + (2 * 24 * 3600 * 1000)); // Increment by 2 days

                  var orderDateStr = orderCreationTime.toLocaleDateString('en-US', {timeZone: 'Asia/Jakarta', day: "2-digit", month: "2-digit"});
                  var shipDateStr = shipTime.toLocaleDateString('en-US', {timeZone: 'Asia/Jakarta', day: "2-digit", month: "2-digit"});
                  var finalShipDateStr = finalShipTime.toLocaleDateString('en-US', {timeZone: 'Asia/Jakarta', day: "2-digit", month: "2-digit"});

                  return order.shipmentStatus !== "SHIPPED" && 
                  (currentDateString !== finalShipDateStr && currentDateString !== shipDateStr && currentDateString !== orderDateStr);
                })?.length} PESANAN
              </Typography>
              <Typography style={{margin: '6px 0px 1px 13px', color: '#000000'}}>
                <b>JUMLAH HARI TERAKHIR KIRIM: </b>{ordersList.filter((order) => {
                  if (order.paymentStatus !== "settlement" && order.paymentStatus !== "capture" && order.paymentStatus !== "capture_stockonly") {
                    return false;
                  }

                  const currentDateString = new Date().toLocaleDateString('en-US', {timeZone: 'Asia/Jakarta', day: "2-digit", month: "2-digit"});

                  const orderCreationTime = new Date(order.creationDateTime); // Ingest ISO time

                  var finalShipTime = new Date();
                  finalShipTime.setTime(orderCreationTime.getTime() + (2 * 24 * 3600 * 1000)); // Increment by 2 days

                  var finalShipDateStr = finalShipTime.toLocaleDateString('en-US', {timeZone: 'Asia/Jakarta', day: "2-digit", month: "2-digit"});

                  return order.shipmentStatus !== "SHIPPED" && currentDateString === finalShipDateStr;
                })?.length} PESANAN
              </Typography>
              <Typography style={{margin: '3px 0px 6px 13px', color: '#000000'}}>
                <b>JUMLAH BELUM KIRIM: </b>{ordersList.filter((order) => {
                  if (order.paymentStatus !== "settlement" && order.paymentStatus !== "capture" && order.paymentStatus !== "capture_stockonly") {
                    return false;
                  }

                  return order.shipmentStatus !== "SHIPPED";
                })?.length} PESANAN
              </Typography>
            </TableRow>
            <TableRow>
              {/* <TableCell>User Sub</TableCell> */}
              <TableCell>Order ID</TableCell>
              <TableCell>Waktu Pesan</TableCell>
              <TableCell>HARUS DIKIRIM</TableCell>
              <TableCell style={{
                    maxWidth: "100px"
                  }}>Total Order/ Payment Amount</TableCell>
              <TableCell style={{
                    maxWidth: "150px"
                  }}>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Payment Status</TableCell>
              <TableCell>Shipment Status</TableCell>
              <TableCell>Ship Complete Time</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Dikasih Meas Kit?</TableCell>
              <TableCell>Form Kerja</TableCell>
              <TableCell>Sticker Item</TableCell>
              <TableCell>Sticker Resi Biasa</TableCell>
              <TableCell>Sticker Resi Panjang</TableCell>
              <TableCell>Sticker Kurir</TableCell>
              <TableCell>Request Pickup</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCryptoOrders.map((order: any) => {
              var orderTimeString = "TIDAK ADA";
              var shipDateString = "LEWAT (!!!)";
              if (order?.creationDateTime) {
                const orderCreationTime = new Date(order.creationDateTime);
                orderTimeString = orderCreationTime.toLocaleString('en-US', {timeZone: 'Asia/Jakarta'});

                var shipTime = new Date();
                shipTime.setTime(orderCreationTime.getTime() + (1 * 24 * 3600 * 1000));
                var finalShipTime = new Date();
                finalShipTime.setTime(orderCreationTime.getTime() + (2 * 24 * 3600 * 1000));

                var orderDateStr = orderCreationTime.toLocaleDateString('en-US', {timeZone: 'Asia/Jakarta', day: "2-digit", month: "2-digit"});
                var shipDateStr = shipTime.toLocaleDateString('en-US', {timeZone: 'Asia/Jakarta', day: "2-digit", month: "2-digit"});
                var finalShipDateStr = finalShipTime.toLocaleDateString('en-US', {timeZone: 'Asia/Jakarta', day: "2-digit", month: "2-digit"});

                const currentDateString = new Date().toLocaleDateString('en-US', {timeZone: 'Asia/Jakarta', day: "2-digit", month: "2-digit"});

                if (currentDateString === finalShipDateStr) {
                  shipDateString = "HARI TERAKHIR (!!)";
                }
                if (currentDateString === shipDateStr) {
                  shipDateString = "HARI INI (!)";
                }
                if (currentDateString === orderDateStr) {
                  shipDateString = "BESOK";
                }
              }

              var shipmentTimeString = "-";
              if (order?.shipmentTime) {
                const orderCreationTime = new Date(order.shipmentTime);
                shipmentTimeString = orderCreationTime.toLocaleString('en-US', {timeZone: 'Asia/Jakarta'});

                // Get time difference
                shipmentTimeString = shipmentTimeString +
                  formatDate(new Date(order.shipmentTime).getTime() - new Date(order.creationDateTime).getTime());
              }

              if (order.shipmentStatus === "SHIPPED") {
                shipDateString = "-";
              }

              return (
                <TableRow
                  hover
                  key={order.orderId}
                  style={order.shipmentStatus === "SHIPPED" ? {
                    backgroundColor: "#b3ffb9"
                  } : shipDateString === "LEWAT (!!!)" ? {
                    backgroundColor: "#ff4545"
                  } : shipDateString === "HARI TERAKHIR (!!)"  ? {
                    backgroundColor: "#f48d2e"
                  } : shipDateString === "HARI INI (!)" ? {
                    backgroundColor: "#ffdb46"
                  } : undefined}
                >
                  {/* <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {order.userSub}
                    </Typography>
                  </TableCell> */}
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {order.orderId + (order?.courierOrderId ? " (" + order.courierOrderId + ")" : "")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {orderTimeString} 
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {shipDateString + (order?.courierSelectionObject?.service ? " (" + order.courierSelectionObject.service.toUpperCase() + ")" : "")} 
                    </Typography>
                  </TableCell>
                  <TableCell style={{
                    maxWidth: "120px"
                  }}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                    >
                      {order.totalCartAmount + "/" + order.totalPaymentAmount}
                    </Typography>
                  </TableCell>
                  <TableCell style={{
                    maxWidth: "150px"
                  }}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                    >
                      {order?.orderName ? order.orderName : "CUST"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" style={{
                    maxWidth: "300px"
                  }}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {
                        order?.orderName === "MARKETPLACE" ? "Pesanan dari Marketplace" :
                        order.address
                      }
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {order.paymentStatus}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {order.shipmentStatus + (order.shipmentStatus === "SHIPPED" ? ": " + order.shipmentTrackingNumber : "") }
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {shipmentTimeString}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                      {
                        (order.paymentStatus === "capture" || order.paymentStatus === "capture_stockonly" || order.paymentStatus === "settlement") 
                        && order.shipmentStatus !== "SHIPPED" ?
                        <Tooltip title="Update Order" arrow>
                          <IconButton
                            sx={{
                              '&:hover': {
                                background: theme.colors.primary.lighter
                              },
                              color: theme.palette.primary.main
                            }}
                            color="inherit"
                            size="small"
                            onClick={() => {
                              dispatch(updateVal(order));
                              dispatch(updateDialogOpen(true));
                            }}
                          >
                            <EditTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>:
                        null
                      }
                      {
                        order.paymentStatus === "unpaid" ?
                        <Tooltip title="Update Order Payment Status" arrow>
                          <IconButton
                            sx={{
                              '&:hover': {
                                background: theme.colors.primary.lighter
                              },
                              color: theme.palette.primary.main
                            }}
                            color="inherit"
                            size="small"
                            onClick={() => {
                              dispatch(updateValPayment(order));
                              dispatch(updateDialogOpenPayment(true));
                            }}
                          >
                            <Payment fontSize="small" />
                          </IconButton>
                        </Tooltip>:
                        null
                      }
                      {
                        (order.paymentStatus === "capture" || order.paymentStatus === "capture_stockonly" || order.paymentStatus === "settlement") 
                        && order.shipmentStatus === "SHIPPED" ?
                        <Button variant="outlined" 
                        disabled={!(order.orderName && order.phoneNumber && order.orderId && order.paidOrderCount && order.shipmentPhoto)}
                        onClick={() => {
                          const customerNotifyBody = {
                            "messageType": "order_shipped",
                            "customerName": order.orderName,
                            "customerNumber": order.phoneNumber,
                            "shipmentNumber": undefined,
                            "orderId": order.orderId,
                            "paidOrderCount": order.paidOrderCount,
                            "shipmentPhoto": "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/" + encodeURIComponent(order.shipmentPhoto) + ".jpg",
                            "courierBrand": order?.courierSelectionObject?.service ? order.courierSelectionObject.service : ''
                          };

                          dispatch(postCustomerNotification(customerNotifyBody));
                        }}>
                          Kabari Ulang
                        </Button>: 
                        null
                      }
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap>
                      {
                        (!order.paidOrderCount || order?.paidOrderCount === 1) ? "KASIH MEAS KIT" : "- (SUDAH PUNYA)"
                      }
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button variant="outlined" disabled={order?.orderName === "MARKETPLACE"} onClick={() => {
                      dispatch(fetchWorkInputFormPdf(order));
                    }}>
                      Print Form
                    </Button>
                  </TableCell>
                  <TableCell style={{display:'flex', flexDirection: 'column'}}>
                    <Box style={{display:'flex', flexDirection: 'row'}}>
                      <Button variant="outlined" disabled={order?.orderName === "MARKETPLACE"} onClick={() => {
                        dispatch(fetchUserPersonalizedLabelsPdf(order));
                      }}>
                        Print Label
                      </Button>
                      <IconButton
                      sx={{
                        '&:hover': {
                          background: theme.colors.primary.lighter
                        },
                        color: theme.palette.primary.main,
                        marginLeft: '15px',
                      }}
                      color="inherit"
                      disabled={!(order.paymentStatus === "capture" || order.paymentStatus === "capture_stockonly" || order.paymentStatus === "settlement")}
                      size="small" onClick={() => {
                        dispatch(updateVal(order));
                        dispatch(updateDialogOpenLabelNama(true));
                      }}>
                        <Payment fontSize="small" />
                      </IconButton>
                    </Box>
                    <Typography
                      style={{
                        marginTop: '5px',                        
                        fontWeight: 300,
                        fontSize: '8px',
                      }}
                      gutterBottom>
                      {
                        order?.labelNamaManualArray?.join(", ")
                      }
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button variant="outlined" disabled={order?.orderName === "MARKETPLACE"} onClick={() => {
                      dispatch(fetchUserShipmentLabelPdf(order));
                    }}>
                      Alamat Biasa
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button variant="outlined" disabled={order?.orderName === "MARKETPLACE"} onClick={() => {
                      dispatch(fetchUserShipmentLabelPdfLong(order));
                    }}>
                      Alamat Panjang
                    </Button>
                  </TableCell>
                  <TableCell>
                    {
                      order.courierAwb && order.courierOrderId && order?.orderName !== "MARKETPLACE"?
                      <Button variant="outlined" onClick={() => {
                        dispatch(fetchUserCourierLabelPdf(order));
                      }}>
                        Print Label Kurir
                      </Button>:
                      order.courierSelectionObject && 
                      order.courierRequestPickupSchedule &&
                      order.courierOrderId &&
                      !order.courierAwb ?
                      <Button variant="outlined" onClick={() => refreshOrderFunction(order.courierOrderId)} 
                      disabled={isRefreshing}>
                        {isRefreshing ? "Mengupdate..." : "Cek Status Resi"}
                      </Button>:
                      null
                    }
                  </TableCell>
                  <TableCell>
                    {
                      order.shipmentStatus !== "SHIPPED" &&
                      order.courierSelectionObject && 
                      !order.courierRequestPickupSchedule && 
                      (order.paymentStatus === "capture" || order.paymentStatus === "capture_stockonly" || order.paymentStatus === "settlement") &&
                      order?.orderName !== "MARKETPLACE" ? 
                      <Button variant="outlined" onClick={() => {
                        dispatch(updateVal(order));
                        dispatch(updateDialogOpenCourier(true));
                      }}>
                        Request Pickup
                      </Button>:
                      order.courierSelectionObject && order.courierRequestPickupSchedule ? 
                      <Typography>PICKUP {order.courierRequestPickupSchedule}</Typography>:
                      null
                    }
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredCryptoOrders.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

RecentOrdersTable.propTypes = {
  ordersList: PropTypes.array.isRequired
};

RecentOrdersTable.defaultProps = {
  ordersList: []
};

export default RecentOrdersTable;
