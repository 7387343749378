import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIRequestStatus } from "common-ts/dist/models/APIRequestStatus";
import { OrderPostState } from "common-ts/dist/models/Orders";
import { fetchOrdersList } from "./OrderListRedux";

const initialState : OrderPostState = {
    orderDraft: {},

    orderUpdateDialogOpen: false,
    orderCourierUpdateDialogOpen: false,
    orderLabelNamaUpdateDialogOpen: false,

    status: APIRequestStatus.Idle,
    error: null
}

export const postOrderUpdate = createAsyncThunk('orderUpdate/postOrderUpdate', async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();

    console.log(`Send: ${JSON.stringify(state.orderUpdate.orderDraft)}`)

    const response = await axios({
        method: 'post',
        url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/order', // Endpoint for order update
        data: state.orderUpdate.orderDraft
    });

    thunkAPI.dispatch(fetchOrdersList(""));

    return response.data
})

export const postOrderCourierPickupRequest = createAsyncThunk('orderUpdate/postOrderCourierPickupRequest', async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();

    let phoneNum = state.orderUpdate.orderDraft.phoneNumber.substr(3);

    if (phoneNum.length < 6) {
        phoneNum = phoneNum.padEnd(6, "0");
    }

    const axiosData = {
        method: 'post',
        url: "https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/kirimin-aja-proxy",
        data: JSON.stringify({
          "axiosConfig": {
            "method": "post",
            "url": "https://client.kiriminaja.com/api/mitra/v2/request_pickup",
            "data": {
                "name" : "Studio S.",
                "address" : "Jl. Alamanda X Blok I 10 No. 16, Jelupang, Serpong Utara, Tangerang Selatan, Banten 15323",
                "phone" : "087813028340",
                "kecamatan_id" : 6315,
                "latitude": -6.258,
                "longitude": 106.663,
                "zipcode" : "15323",
                "schedule" : state.orderUpdate.orderDraft.courierRequestPickupSchedule,
                "packages" : [
                    {
                        "order_id": state.orderUpdate.orderDraft.courierOrderId,
                        "destination_name": state.orderUpdate.orderDraft.orderName.substring(0, 50),
                        "destination_address": (state.orderUpdate.orderDraft.address + ", " + state.orderUpdate.orderDraft.kotaKecamatanText 
                        + " " + state.orderUpdate.orderDraft.kodePos 
                        + " " + (state.orderUpdate.orderDraft?.keterangan ? ("(" + state.orderUpdate.orderDraft.keterangan) + ")": "")).substring(0, 200),
                        "destination_kecamatan_id": state.orderUpdate.orderDraft.destinationKiriminAjaObject.kecamatan_id,
                        "destination_phone": "0" + phoneNum,
                        "weight": state.orderUpdate.orderDraft.courierWeight,
                        "width": 35,
                        "height": 1 * (state.orderUpdate.orderDraft.courierWeight / 250),
                        "length": 30,
                        "item_value": state.orderUpdate.orderDraft.totalCartAmount,
                        "shipping_cost": Number(state.orderUpdate.orderDraft.courierSelectionObject.cost),
                        "service": state.orderUpdate.orderDraft.courierSelectionObject.service,
                        "service_type": state.orderUpdate.orderDraft.courierSelectionObject.service_type,
                        "item_name": "Baju",
                        "package_type_id": 1,
                        "cod": 0
                    }
                ]
            }
          }
        })
    };
    
    console.log(`Send: ${JSON.stringify(state.orderUpdate.orderDraft)}`);

    // Request Pickup to KiriminAja
    const response1 = await axios(axiosData);
    
    // SUCCESS RESPONSE
    // {
    //          "status": true,
    //          "text": "Request pickup berhasil",
    //          "method": "request_pickup",
    //          "qr_url": "some-random-qr-string",
    //          "payment_status": "unpaid",
    //          "details": [
    //             {
    //                 "order_id": "SDS-000000019",
    //                 "kj_order_id": "SDS-000000019",
    //                 "awb": null,
    //                 "service": "SICEPAT",
    //                 "service_type": "SIUNT"
    //             }
    //          ],
    //          "pickup_number": "XID-7716057437"
    // }

    // FAIL RESPONSE
    // {
    // "status": false,
    // "text": "Packages.0.height wajib diisi.",
    // "method": "request_pickup",
    // "fail_package": {
    //     "order_id": "SDS-000000019",
    //     "destination_name": "Flag Test",
    //     "destination_address": "Jl. Magelang KM 11",
    //     "destination_kecamatan_id": 419,
    //     "destination_phone": "082223323333",
    //     "weight": 520,
    //     "width": 8,
    //     "length": 8,
    //     "item_value": 275000,
    //     "shipping_cost": 65000,
    //     "service": "sicepat",
    //     "service_type": "SIUNT",
    //     "item_name": "Baju",
    //     "package_type_id": 1,
    //     "cod": 0
    // }
    // }

    // REPEAT RESPONSE
    // {
    //          "status": false,
    //          "text": "1 paket tidak bisa diproses ulang. Status belum dicancel owner",
    //          "method": "request_pickup",
    //          "packages": [
    //          {
    //              "order_id": "SDS-000000019",
    //              "status": "Menunggu Dibayar",
    //              "status_code": 110
    //          }
    // ]
    // }

    // If we get a REPEAT RESPONSE, we close the dialog and update our own server / db
    if (!response1.data?.returnBody?.status && response1.data?.returnBody?.packages?.[0]?.order_id) {

        // Update order REPEAT RESPONSE 
        const response = await axios({
            method: 'post',
            url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/order', // Endpoint for order update
            data: {
                ...state.orderUpdate.orderDraft,
                updateType: "courierStatus",
                courierRequestPickupSchedule: "SUDAH DIMINTA",
                courierRequestPickupScheduleObject: {},
            }
        });

        thunkAPI.dispatch(fetchOrdersList(""));

        return {success: true};
    }
    // If we get a FAIL RESPONSE, we keep the dialog open
    else if (response1.data?.returnBody?.status === false) {
        return {success: false};
    }

    const response = await axios({
        method: 'post',
        url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/order', // Endpoint for order update
        data: {
            ...state.orderUpdate.orderDraft,
            updateType: "courierStatus",
        }
    });

    thunkAPI.dispatch(fetchOrdersList(""));

    return response.data
})

export const postCustomerNotification = createAsyncThunk('orderUpdate/postCustomerNotification', async (arg: any, thunkAPI) => {
    axios({
        method: 'post',
        url: "https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/customer-notify",
        data: arg
    });

    return {};
})

export const postOrderLabelNamaUpdate = createAsyncThunk('orderUpdate/postOrderLabelNamaUpdate', async (arg: string[], thunkAPI) => {
    const state: any = thunkAPI.getState();

    // Update order REPEAT RESPONSE 
    const response = await axios({
        method: 'post',
        url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/order', // Endpoint for order update
        data: {
            userSub: state.orderUpdate.orderDraft.userSub,
            creationDateTime: state.orderUpdate.orderDraft.creationDateTime,
            updateType: "labelNamaUpdate",
            labelNamaManualArray: arg ?? [],
        }
    });

    thunkAPI.dispatch(fetchOrdersList(""));

    return response.data
})

export const orderUpdateSlice = createSlice({
    name: 'orderUpdate',
    initialState,
    reducers: {
        updateVal(state, action) {
            state.orderDraft = action.payload;
            console.log(`State values: ${JSON.stringify(state.orderDraft)}`);
        },
        addVal(state, action) {
            for (let key in action.payload) {
                state.orderDraft[key] = action.payload[key];
            }
            console.log(`State values: ${JSON.stringify(state.orderDraft)}`);
        },
        updateDialogOpen(state, action) {
            state.orderUpdateDialogOpen = action.payload;
        },
        updateDialogOpenCourier(state, action) {
            state.orderCourierUpdateDialogOpen = action.payload;
        },
        updateDialogOpenLabelNama(state, action) {
            state.orderLabelNamaUpdateDialogOpen = action.payload;
        }
    },
    extraReducers(builder) {
      builder
        .addCase(postOrderUpdate.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
        })
        .addCase(postOrderUpdate.fulfilled, (state, action) => {
            // We reset the order draft and close the dialog if it is successful
            if (action.payload.success) {
                state.orderDraft = {};
                state.orderUpdateDialogOpen = false;
            }

            state.status = APIRequestStatus.Success;
        })
        .addCase(postOrderUpdate.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("fetch is failure");
        })
        .addCase(postCustomerNotification.pending, (state, action) => {
        })
        .addCase(postCustomerNotification.fulfilled, (state, action) => {
        })
        .addCase(postCustomerNotification.rejected, (state, action) => {
        })
        .addCase(postOrderCourierPickupRequest.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
        })
        .addCase(postOrderCourierPickupRequest.fulfilled, (state, action) => {
            // We reset the order draft and close the dialog if it is successful
            if (action.payload.success) {
                state.orderDraft = {};
                state.orderCourierUpdateDialogOpen = false;
            }

            state.status = APIRequestStatus.Success;
        })
        .addCase(postOrderCourierPickupRequest.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("fetch is failure");
        })
        .addCase(postOrderLabelNamaUpdate.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
        })
        .addCase(postOrderLabelNamaUpdate.fulfilled, (state, action) => {
            // We reset the order draft and close the dialog if it is successful
            state.orderDraft = {};
            state.orderLabelNamaUpdateDialogOpen = false;

            state.status = APIRequestStatus.Success;
        })
        .addCase(postOrderLabelNamaUpdate.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("fetch is failure");
        })
    }
})

export const { updateVal, updateDialogOpen, addVal, updateDialogOpenCourier, updateDialogOpenLabelNama } = orderUpdateSlice.actions

export default orderUpdateSlice.reducer