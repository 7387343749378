import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import axios from 'axios';
import { fetchFilesList } from "./FileListRedux";
import { postCustomerNotification } from "./OrderPostRedux";

const initialState: any = {
    fileDraft: {},

    fileCreateDialogOpen: false,

    status: APIRequestStatus.Idle,
    error: ''
}

const newFileSlice = createSlice({
    name: 'fileCreate',
    initialState,
    reducers: {
        updateVal(state, action) {
            state.fileDraft = action.payload
            console.log(`State values: ${JSON.stringify(state.fileDraft)}`)
        },
        updateDialogOpen(state, action) {
            state.fileCreateDialogOpen = action.payload;
        }
    },
    extraReducers(builder) {
        builder
          .addCase(postMultipleNewFiles.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postMultipleNewFiles.fulfilled, (state, action) => {
            state.status = APIRequestStatus.Success;

            if (action.payload.postFileInformationSuccess) {
              state.fileCreateDialogOpen = false;
            } 
          })
          .addCase(postMultipleNewFiles.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("post is failure");
          })
          .addCase(uploadCapturedImageAndNotifyCustomer.pending, (state, action) => {
          })
          .addCase(uploadCapturedImageAndNotifyCustomer.fulfilled, (state, action) => {
          })
          .addCase(uploadCapturedImageAndNotifyCustomer.rejected, (state, action) => {
          })
      }
})

function dataURItoBlob(dataURI) {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for(var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

export const uploadCapturedImageAndNotifyCustomer = createAsyncThunk('fileCreate/uploadCapturedImageAndNotifyCustomer', async (arg: {
  fileContent: any,
  orderId: string,
  notifyContents: any,
}, thunkAPI) => {

  var postFileInformationSuccess = true;
  var currentFileDraft: any = {};
  currentFileDraft.fileName = "shipment-photo-" + arg.orderId + ".jpg"; // Remove whitespace
  currentFileDraft.fileDataType = 'image/jpeg';
  currentFileDraft.fileCategory = 'general';

  const signedURLResponse = await axios({
    method: 'post',
    url: 'https://w3q706jlua.execute-api.ap-southeast-1.amazonaws.com/FileSystemAPIProduction/signedurl',
    data: currentFileDraft
  })
  
  console.log(signedURLResponse);
  const uploadURL = signedURLResponse.data.uploadURL;
  const createdFileKey = signedURLResponse.data.fileKey;

  try {
    const uploadResult = await axios({
      method: 'put',
      url: uploadURL,
      data: dataURItoBlob(arg.fileContent),
      headers: {
        "Content-Type": currentFileDraft.fileDataType,
      }
    })

    console.log("upload to s3 success:");
    console.log(uploadResult.headers);
    console.log(uploadResult.statusText);
    console.log(uploadResult);
  } catch (err) {
    console.log("Error uploading to s3:");
    console.log(err);
  }
  
  console.log("continue...")
  const fileURL = uploadURL.split('?')[0]
  
  // var fileInformation = {
  //   fileName: currentFileDraft.fileName,
  //   fileDataType: currentFileDraft.fileDataType,
  //   fileCategory: currentFileDraft.fileCategory,
  //   fileURL: fileURL,
  //   fileKey: createdFileKey,
  //   fileCategoryData: ""
  // }

  // try {
  //   const response = await axios({
  //     method: 'post',
  //     url: 'https://w3q706jlua.execute-api.ap-southeast-1.amazonaws.com/FileSystemAPIProduction/files',
  //     data: fileInformation
  //   });
  //   console.log(response);
  //   postFileInformationSuccess = postFileInformationSuccess && response.data.postFileInformationSuccess;
  // } catch (err) {
  //   console.log("fail to execute 2nd axios:")
  //   console.log(err)
  // }

  await thunkAPI.dispatch(postCustomerNotification({
    ...arg.notifyContents,
    shipmentPhoto: fileURL,
  }))

  return ({
    postFileInformationSuccess: postFileInformationSuccess
  });
});

export const postMultipleNewFiles = createAsyncThunk('fileCreate/postMultipleNewFiles', async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();
    var fileDraft = state.fileCreate.fileDraft;

    var postFileInformationSuccess = true;

    for (var fileIndex = 0; fileIndex < fileDraft.fileList.length; fileIndex = fileIndex + 1) {
      console.log("Uploading file number " + (fileIndex + 1));
      var currentFileDraft: any = {};
      currentFileDraft.file = fileDraft.fileList[fileIndex];
      currentFileDraft.fileName = currentFileDraft.file.name.replace(/ /g, '-'); // Remove whitespace
      currentFileDraft.fileDataType = currentFileDraft.file.type;
      currentFileDraft.fileCategory = fileDraft.fileCategory;
      currentFileDraft.fileCategoryData = fileDraft.fileCategoryData;

      const signedURLResponse = await axios({
        method: 'post',
        url: 'https://w3q706jlua.execute-api.ap-southeast-1.amazonaws.com/FileSystemAPIProduction/signedurl',
        data: currentFileDraft
      })
      
      console.log(signedURLResponse);
      const uploadURL = signedURLResponse.data.uploadURL;
      const createdFileKey = signedURLResponse.data.fileKey;

      try {
        const uploadResult = await axios({
          method: 'put',
          url: uploadURL,
          data: currentFileDraft.file,
          headers: {
            "Content-Type": currentFileDraft.fileDataType
          }
        })

        console.log("upload to s3 success:");
        console.log(uploadResult.headers);
        console.log(uploadResult.statusText);
      } catch (err) {
        console.log("Error uploading to s3:");
        console.log(err);
      }
      
      console.log("continue...")
      const fileURL = uploadURL.split('?')[0]

      var categoryData = currentFileDraft.fileCategoryData ? currentFileDraft.fileCategoryData : "";
      
      var fileInformation = {
        fileName: currentFileDraft.fileName,
        fileDataType: currentFileDraft.fileDataType,
        fileCategory: currentFileDraft.fileCategory,
        fileURL: fileURL,
        fileKey: createdFileKey,
        fileCategoryData: categoryData
      }

      console.log(fileInformation);
      console.log("Putting item to table");

      try {
        const response = await axios({
          method: 'post',
          url: 'https://w3q706jlua.execute-api.ap-southeast-1.amazonaws.com/FileSystemAPIProduction/files',
          data: fileInformation
        });
        console.log(response);
        postFileInformationSuccess = postFileInformationSuccess && response.data.postFileInformationSuccess;
      } catch (err) {
        console.log("fail to execute 2nd axios:")
        console.log(err)
      }
    }

    thunkAPI.dispatch(fetchFilesList());

    return ({
      postFileInformationSuccess: postFileInformationSuccess
    });
})

export const { updateVal, updateDialogOpen } = newFileSlice.actions

export default newFileSlice.reducer