import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIRequestStatus } from "common-ts/dist/models/APIRequestStatus";

const initialState : {
    employeeDict: {},
    employeeFieldIdDict: {},

    status: APIRequestStatus,
    error: string | null,
} = {
    employeeDict: {},
    employeeFieldIdDict: {},

    status: APIRequestStatus.Idle,
    error: null
}

export const fetchEmployeeList = createAsyncThunk('employeeDict/fetchEmployeeList', async () => {
    const response = await axios({
        method: 'get',
        url: 'https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/employee',
    });
    return response.data
})

export const employeeDictSlice = createSlice({
    name: 'employeeDict',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
      builder
        .addCase(fetchEmployeeList.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
        })
        .addCase(fetchEmployeeList.fulfilled, (state, action) => {
            const empDict = {};
            for (let obj of (action.payload.employeeList ?? [])) {
                empDict[obj.employeeId] = obj;
            }
            state.employeeDict = empDict;
            const empFieldDict = {};
            for (let obj of (action.payload.employeeFieldIdList ?? [])) {
                empFieldDict[obj.employeeId] = obj;
            }
            state.employeeFieldIdDict = empFieldDict;

            state.status = APIRequestStatus.Success;
        })
        .addCase(fetchEmployeeList.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            // state.error = action.error.message
            console.log("fetch is failure");
        })
    }
})

export default employeeDictSlice.reducer